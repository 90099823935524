import { Route, Routes, Navigate } from "react-router-dom";
import React, { Suspense, lazy, Fragment } from "react";
import classes from "./Main.module.scss";

import Container from "react-bootstrap/Container";
import SuspenseLoader from "./SuspenseLoader/SuspenseLoader";

import InvalidRoute from "../InvalidRoute/InvalidRoute";

const Home = lazy(() => import("../Home/Home"));
const Order = lazy(() => import("../Order/Order"));
const OrderComplete = lazy(() => import("../OrderComplete/OrderComplete"));
const Letter = lazy(() => import("../Letter/Letter"));
const LetterDetails = lazy(() => import("../LetterDetails/LetterDetails"));
const Extras = lazy(() => import("../Extras/Extras"));
const Packages = lazy(() => import("../Packages/Packages"));
const Contact = lazy(() => import("../Contact/Contact"));
const FAQ = lazy(() => import("../FAQ/FAQ"));
const OrdersClosed = lazy(() => import("../OrdersClosed/OrdersClosed"));
const PreLaunch = lazy(() => import("../PreLaunch/PreLaunch"));

const Main = () => {
  // eslint-disable-next-line
  const preLaunch = (
    <Fragment>
      <Route path="order/" element={<PreLaunch />} />
      <Route path="order/success" element={<PreLaunch />} />
      <Route path="order/:page" element={<PreLaunch />} />
    </Fragment>
  );
  // eslint-disable-next-line
  const normalOps = (
    <Fragment>
      <Route
        path="order/"
        element={<Navigate replace to="/order/HowItWorks" />}
      />
      <Route path="order/success" element={<OrderComplete />} />
      <Route path="order/:page" element={<Order />} />
    </Fragment>
  );
  // eslint-disable-next-line
  const ordersClosed = (
    <Fragment>
      <Route path="order/" element={<OrdersClosed />} />
      <Route path="order/success" element={<OrdersClosed />} />
      <Route path="order/:page" element={<OrdersClosed />} />
    </Fragment>
  );

  //Set what state the store is in (preLaunch, normalOps, or ordersClosed)
  let orderStatus;
  switch (process.env.NODE_ENV) {
    case "production":
      orderStatus = normalOps;
      break;
    case "development":
      orderStatus = normalOps;
      break;
    default:
      orderStatus = preLaunch;
      break;
  }

  let routes = (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="Letters" element={<Letter />} />
      <Route path="Extras" element={<Extras />} />
      <Route path="Packages" element={<Packages />} />
      <Route path="Contact_Us" element={<Contact />} />
      <Route path="FAQ" element={<FAQ />} />
      <Route
        path="letters/Santa's_Stationery"
        element={<LetterDetails letterStyle="SS" />}
      />
      <Route
        path="letters/Festive_Friends"
        element={<LetterDetails letterStyle="FF" />}
      />
      <Route
        path="letters/Winter_Wonderland"
        element={<LetterDetails letterStyle="WW" />}
      />
      <Route
        path="letters/The_Big_Red_Suit"
        element={<LetterDetails letterStyle="BRS" />}
      />
      <Route
        path="letters/Under_The_Tree"
        element={<LetterDetails letterStyle="UTT" />}
      />
      {orderStatus}
      <Route path="*" element={<InvalidRoute />} />
    </Routes>
  );
  return (
    <Suspense fallback={<SuspenseLoader />}>
      <Container fluid className={`min-vh-100 ${classes.outer_wrapper}`}>
        {routes}
      </Container>
    </Suspense>
  );
};

export default Main;
